<template>
  <div class="container">
    <div class="box">
      <div class="item" v-for="item in 6"
        :key="item">
        <div class="info">
          <div class="dfc">
            <img src="https://source.aidogcatpet.com/forit/trophy.png" alt="" />
            <div>
              <div class="name">YYQQ.916</div>
              <div class="num">成交：<span>2000</span>笔</div>
            </div>
          </div>
          <div>
            <div class="num">排名</div>
            <div class="ranking">NO.1</div>
          </div>
        </div>
        <div class="data">
          <h4>做好自己，一切看天意</h4>
          <div class="dfc">
            <div>
              <div class="msg">最近交易</div>
              <div>￥51000</div>
            </div>
            <div>
              <div class="msg">上次排名</div>
              <div>TOP5</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "regional",
  data() {
    return {
      dialogVisible: false,
      value1: [],
      input: "",
      options: [
        {
          value: "0",
          label: "待查看",
        },
        {
          value: "1",
          label: "已查看",
        },
      ],
      value: "",
      messageList: [],
      total: 0,
      order_by: "",
      page: 1,
      limit: 10,
      unViewNum: 0,
      messageDetail: {},
    };
  },
  created() {
    this.getMessageList();
  },
  computed: {
    shopInfo() {
      return JSON.parse(window.localStorage.getItem("shopInfo")) || {};
    },
  },
  methods: {
    getMessageDetail(data) {
      window.open(data.contract_enclosure_img);
    },
    getMessageList(e) {
      // 获取消息列表
      this.$http2
        .get("/merapi/shop/electronic/signature/list")
        .then((res) => {
          console.log(res.data.data);
          this.messageList = res.data.data;
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
  },
};
</script>
  
<style scoped lang="less">
.container {
  .box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      width: 30%;
      margin-bottom: 30px;
      background-color: #fff;
      .dfc {
        display: flex;
        align-items: center;
      }
      .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 30px;
        padding: 20px 0;
        border-bottom: 1px solid #ededed;
        .name {
          color: #000;
          font-size: 22px;
          margin-bottom: 8px;
          font-weight: 600;
        }
        .num {
          color: #adadad;
          font-size: 18px;
        }
        .ranking {
          color: #f89044;
          font-size: 22px;
          margin-top: 8px;
        }
        img {
          width: 100px;
          height: 100px;
          border-radius: 50px;
        }
      }
      .data {
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        text-align: center;
        color: #000;
        h4 {
          margin-bottom: 30px;
        }
        .msg {
          color: #adadad;
          margin: 8px 18px;
        }
      }
    }
  }
}
</style>
  